html{
  background-color: #f0ffea;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0ffea;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-group{
  margin-bottom: 1rem;
  display: flex;
}
.form-group label{
  min-width: 10em;
}
.form-group.no-flex{
  display: inherit;
}

hr{
  margin: 2px;
}
.font-weight-bold{
  font-weight: bold;
}
.form-check-input{
  margin-right: 0.3em;
}
.prevDataArea{
  width: fit-content;
  margin: auto;
  text-align: left;
}
.prevData{
  min-width:6rem;
}
.prevOilingDataArea{
  width: 100%;
  margin: auto;
  text-align: left;
  border: 1px solid rgb(119, 119, 119);
  border-radius: 10px;
  padding: 5px;
}
.historyBranch{
  width: 1rem;
  height: 2rem;
  border-left: 1px solid rgb(119, 119, 119);
  margin-left: 1rem;
}